import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'
import {v4 as uuidv4} from 'uuid';
const tableRowsCount = 11;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        fontSize: 10,
        marginBottom: 24
    },
});

const InvoiceItemsTable = ({invoice}) => (
    <View key={uuidv4()} style={styles.tableContainer}>
        <InvoiceTableHeader />
        <InvoiceTableRow items={invoice.items} />
        <InvoiceTableBlankSpace rowsCount={ Math.ceil(invoice.items.length / 9) * 9 - invoice.items.length} />
        <InvoiceTableFooter invoice={invoice} />
    </View>
);

export default InvoiceItemsTable
