import React from 'react';
import {StyleSheet, Text, View} from '@react-pdf/renderer';
import {v4 as uuidv4} from 'uuid';

const borderColor = '#000000'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        // height: 24,
        fontSize: 12,
        fontWeight: 600,
    },
    blankSpace: {
        width: '50%',
        textAlign: 'left',
        flexWrap: 'wrap',
    },
    totalDescription: {
        borderBottom: '0.8 solid #000000',
        width: '35%',
        textAlign: 'right',
        paddingTop: 5
    },
    totalTotal: {
        borderBottom: '0.8 solid #000000',
        width: '25%',
        textAlign: 'right',
        paddingRight: 15,
        paddingTop: 5
    },
    description: {
        width: '35%',
        textAlign: 'right',
        paddingTop: 5
    },
    total: {
        width: '25%',
        textAlign: 'right',
        paddingTop: 5,
        paddingRight: 15,
    },
});

const InvoiceTableFooter = ({invoice}) => {
    const totalPrice = invoice.totalPrice;
    const maxPrice = invoice.maxPrice;
    const totalWithGst = totalPrice * 1.1;
    const maxWithGst = maxPrice * 1.1;
    const totalgst = totalPrice * 0.1;
    const maxgst = maxPrice * 0.1;

    const hasShowerScreen = invoice.items.some(item => item?.name?.toLowerCase().includes("shower screen"));

    return (
        <>
            <View key={uuidv4()} style={styles.row}>
                <View style={styles.blankSpace}>
                    {hasShowerScreen && (<>
                    <Text>Notes:</Text>
                    <Text>Please note the price for shower screen supply</Text>)</>)}
                </View>
                <Text style={styles.description}>Total Price:</Text>
                <Text style={styles.total}>
                    {maxPrice > 0 && maxPrice > totalPrice ?
                        `${Number.parseFloat(totalPrice).toFixed(2)} - ${Number.parseFloat(maxPrice).toFixed(2)}`
                        :
                        Number.parseFloat(totalPrice).toFixed(2)
                    }
                </Text>
            </View>
            <View key={uuidv4()} style={styles.row}>
                <View style={styles.blankSpace}>
                    {hasShowerScreen && (<>
                    <Text>is the highest price</Text>
                    <Text>we need to measure the size and </Text></>)}
                </View>
                <Text style={styles.totalDescription}>Total Gst(10%):</Text>
                <Text style={styles.totalTotal}>
                    {maxPrice > 0 && maxPrice > totalPrice ?
                        `${Number.parseFloat(totalgst).toFixed(2)} - ${Number.parseFloat(maxgst).toFixed(2)}`
                        :
                        Number.parseFloat(totalgst).toFixed(2)
                    }
                </Text>
            </View>
            <View key={uuidv4()} style={styles.row}>
                <View style={styles.blankSpace}>
                    {hasShowerScreen && (<>
                    <Text>then send you the accurate price.</Text></>)}
                </View>
                <Text style={styles.totalDescription}>Total AUD:</Text>
                <Text style={styles.totalTotal}>
                    {maxPrice > 0 && maxPrice > totalPrice ?
                        `${Number.parseFloat(totalWithGst).toFixed(2)} - ${Number.parseFloat(maxWithGst).toFixed(2)}`
                        :
                        Number.parseFloat(totalWithGst).toFixed(2)
                    }
                </Text>
            </View>
        </>
    )
};


export default InvoiceTableFooter
