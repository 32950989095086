import React, {Fragment} from 'react';
import {Font, StyleSheet, Text, View} from '@react-pdf/renderer';
import {v4 as uuidv4} from 'uuid';

const fetchAndRegisterFonts = async () => {
    try {
        const response = await fetch(
            'https://www.googleapis.com/webfonts/v1/webfonts?subset=chinese-simplified&key=AIzaSyAaVEOriV4aXDKT_twt-OO5QLS7T5hjPzo'
        );
        const data = await response.json();
        data.items.map((item) => {
                let fontsArray = []
                Object.entries(item.files).forEach(([key, value]) => {
                    let computedKey = key;
                    if (key === 'regular') {
                        computedKey = "400";
                    }
                    if (!isNaN(computedKey)) {
                        fontsArray.push({
                            src: value.replace("http://", "https://"),
                            fontWeight: parseInt(computedKey)
                        });
                    }
                });
                Font.register({
                    family: item.family,
                    fonts: fontsArray,
                });
            }
        );
    } catch (error) {
        console.error('Error fetching fonts data:', error);
    }
};

await fetchAndRegisterFonts();

const borderColor = '#ffffff';

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottom: '0.4 solid rgba(0,0,0,0.1)',
        alignItems: 'flex-start', // Changed from center to flex-start to top-align content
        minHeight: 24,  // Changed from height to minHeight
    },
    description: {
        fontFamily: 'Noto Sans SC',
        width: '50%',
        fontWeight: '400',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        marginVertical: 0,
        flexWrap: 'wrap', // Added this line
        paddingRight: 15,  // Padding to ensure text doesn't touch the border
    },
    qty: {
        width: '20%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        flexWrap: 'wrap', // Added this line
        paddingRight: 15,  // Padding to ensure text doesn't touch the border
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        flexWrap: 'wrap',
        paddingRight: 15,  // Padding to ensure text doesn't touch the border
    },
    amount: {
        width: '15%',
        textAlign: 'right',
        flexWrap: 'wrap', // Added this line
        paddingRight: 15,
    },
});



const InvoiceTableRow = ({items}) => {
    const rows = items.map(item => {
        // Display the range for unitPrice and unitMaxPrice
        let unitPriceRange = (item.unitMaxPrice > 0 && item.unitMaxPrice > item.unitPrice)
            ? `${item.unitPrice.toFixed(2)} -\n ${item.unitMaxPrice.toFixed(2)}`
            : item.unitPrice?.toFixed(2);

        // Display the range for totalPrice and totalMaxPrice
        let totalPriceRange = (item.totalMaxPrice > 0 && item.totalMaxPrice > item.totalPrice)
            ? `${item.totalPrice.toFixed(2)} -\n ${item.totalMaxPrice.toFixed(2)}`
            : item.totalPrice?.toFixed(2);

        return (
            <View key={uuidv4()} style={styles.row} key={item.id}>
                <View style={styles.description}>
                    <Text>{item?.name}</Text>
                    <Text>{item?.description}</Text>
                </View>
                <Text style={styles.qty}>{item?.quantity}</Text>
                <Text style={styles.rate}>{unitPriceRange}</Text>
                <Text style={styles.rate}>10%</Text>
                <Text style={styles.amount}>{totalPriceRange}</Text>
            </View>
        );
    });

    return (<Fragment>{rows}</Fragment>);
};

export default InvoiceTableRow
