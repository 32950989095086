import React from 'react';
import {Document, Image, Page, StyleSheet, View} from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle'
import BillTo from './BillTo'
import InvoiceDate from './InvoiceDate'
import InvoiceItemsTable from './InvoiceItemsTable'
import InvoiceThankYouMsg from './InvoiceThankYouMsg'
import logo from '../../../src/newboda.jpeg'
import {v4 as uuidv4} from 'uuid';
import InvoiceAddress from "./InvoiceAddress";


const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 30,
        paddingRight: 30,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    logo: {
        width: 150,
        height: 66,
        marginLeft: 'auto',
        marginRight: '0'
    },
    container: {
        display: 'flex', // Add flex display for title and bill to
        justifyContent: 'space-between', // Align items with space between them
        flexDirection: 'row', // Arrange items in a row
        width: '100%', // Make sure the container spans the entire width
    },
});

const Invoice = ({invoice}) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View key={uuidv4()} key={uuidv4()} style={styles.container}>
                <BillTo invoice={invoice}/>
                <Image style={styles.logo} src={logo}/>

            </View>
            <InvoiceDate invoice={invoice}/>
            <View key={uuidv4()} style={styles.container}>
                <InvoiceTitle title='Quote' quoteId={invoice.quoteId}/>

            </View>

            <InvoiceAddress jobAddress={invoice.jobAddress} customerName={invoice.selectedUserName}/>
            <InvoiceItemsTable invoice={invoice}/>

            {/*<InvoiceThankYouMsg/>*/}
        </Page>
    </Document>
);

export default Invoice
