import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import {v4 as uuidv4} from 'uuid';

const styles = StyleSheet.create({

    addressContainer:{
        flexDirection: 'column',
        marginTop: 12,
    },
    addressTitle:{
        color: '#000000',
        fontSize: 12,
        textAlign: 'left',

    }
});


const InvoiceAddress = ({customerName, jobAddress}) => (
    <View key={uuidv4()} style={styles.addressContainer}>
        <Text style={styles.addressTitle}>Customer: {customerName}</Text>
        <Text style={styles.addressTitle}>Job Location: {jobAddress}</Text>
    </View>
)


export default InvoiceAddress
