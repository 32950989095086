export const invoiceData = {
    id: "5df3180a09ea16dc4b95f910",
    invoice_no: "201906-28",
    balance: "$2,283.74",
    company: "New Boda Maintenance Pty Ltd",
    email: "info@newboda.com",
    phone: "03 9563 2204",
    address: "Unit 3/5 Phillip Court Port Melbourne VIC 3207 Australia",
    abn: "ABN: 74 612 060 312",
    trans_date: "2019-09-12",
    due_date: "2019-10-12",
    items: [

    ],
};
